import { createState, useState, self } from '@hookstate/core';
import branding from './branding/branding';


const localStorageLanguageKey = 'virtualGuide-language'

export function getActiveLanguage(): string {
  var language = localStorage.getItem(localStorageLanguageKey)
  if (!language) {
    language = navigator.language.slice(0, 2)
  }

  const availableLanguages = branding.availableLanguages
  if (availableLanguages.indexOf(language) > -1) {
    return language
  } else {
    return availableLanguages[0]
  }
}


/*********************************************************************************************
 * TODO auto generate code
**********************************************************************************************/

let strings = {
  availableLanguages: branding.availableLanguages,
  language: getActiveLanguage(),
  strings: {
    "en": {
      globalStatePopupTexts: {
        errorNoCameraPermission: branding.globalStatePopupTexts.errorNoCameraPermission.en,
        errorNoScreenCapturePermission: branding.globalStatePopupTexts.errorNoScreenCapturePermission.en
      },
      globalTexts: {
        usersOnline: branding.globalTexts.usersOnline.en,
        usersOnPage: branding.globalTexts.usersOnPage.en,
        privacyPolicyTitle: branding.globalTexts.privacyPolicyTitle.en,
        privacyPolicyText: branding.globalTexts.privacyPolicyText.en,
        privacyPolicyAgreeText: branding.globalTexts.privacyPolicyAgreeText.en,
        privacyPolicyDisagreeText: branding.globalTexts.privacyPolicyDisagreeText.en,
        confirm: branding.globalTexts.confirm.en,
        cancel: branding.globalTexts.cancel.en,
      },
      ticketSale: {
        getYourTicketButton: branding.ticketSale.getYourTicketButton.en,
        getYourTicketNowButton: branding.ticketSale.getYourTicketNowButton.en,
        leadDescriptionText: branding.ticketSale.leadDescriptionText.en,
        commingText: branding.ticketSale.commingText.en,
        checkYourSystemDescriptionText1: branding.ticketSale.checkYourSystemDescriptionText1.en,
        checkYourSystemDescriptionText2: branding.ticketSale.checkYourSystemDescriptionText2.en,
        checkYourSystemButton: branding.ticketSale.checkYourSystemButton.en,
        imprintLink: branding.ticketSale.imprintLink.en,
        imprintLinkUrl: branding.ticketSale.imprintLinkUrl.en,
        ticketsUrl: branding.ticketSale.ticketsUrl.en,
      },
      loginRegistrationSite: {
        submitEmailButton: branding.loginRegistrationSite.submitEmailButton.en,
        emailPlaceHolder: branding.loginRegistrationSite.emailPlaceHolder.en,
        passwordPlaceHolder: branding.loginRegistrationSite.passwordPlaceHolder.en,
        submitTokenButton: branding.loginRegistrationSite.submitTokenButton.en,
        tokenPlaceholder: branding.loginRegistrationSite.tokenPlaceholder.en,
        invalidToken: branding.loginRegistrationSite.invalidToken.en,
        wrongToken: branding.loginRegistrationSite.wrongToken.en,
        createNewToken: branding.loginRegistrationSite.createNewToken.en,
        noToken: branding.loginRegistrationSite.noToken.en,
        resendToken: branding.loginRegistrationSite.resendToken.en,
        ticketPanel: branding.loginRegistrationSite.ticketPanel.en,
        buyTicketButton: branding.loginRegistrationSite.buyTicketButton.en,
        changeEmailButton: branding.loginRegistrationSite.changeEmailButton.en,
        createTokenFailed: branding.loginRegistrationSite.createTokenFailed.en,
        createTokenSuccess: branding.loginRegistrationSite.createTokenSuccess.en,
        dataPrivacyPanel: branding.loginRegistrationSite.dataPrivacyPanel.en,
        dataPolicy: branding.loginRegistrationSite.dataPolicy.en,
        invalidEmailPanel: branding.loginRegistrationSite.invalidEmailPanel.en,
        outdatedPrivacyDocsPanel: branding.loginRegistrationSite.outdatedPrivacyDocsPanel.en,
        registerEmailFailed: branding.loginRegistrationSite.registerEmailFailed.en,
        wrongPasswordPanel: branding.loginRegistrationSite.wrongPasswordPanel.en,
        forgotPassword: branding.loginRegistrationSite.forgotPassword.en,
        enterEmail: branding.loginRegistrationSite.enterEmail.en,
        enterPassword: branding.loginRegistrationSite.enterPassword.en,
        enterToken: branding.loginRegistrationSite.enterToken.en,
        centerLoginStyleTitle: branding.loginRegistrationSite.centerLoginStyleTitle.en,
      },
      receptionPage: {
        receptionPageTitleDescription: branding.receptionPage.receptionPageTitleDescription.en,
        receptionPageLobbyHeaderTitle: branding.receptionPage.receptionPageLobbyHeaderTitle.en,
        receptionPageLobbyHeaderDescription: branding.receptionPage.receptionPageLobbyHeaderDescription.en,
        receptionPageMyHeaderTitle: branding.receptionPage.receptionPageMyHeaderTitle.en,
        receptionPageMyHeaderDescription: branding.receptionPage.receptionPageMyHeaderDescription.en,
        visitMyProfile: branding.receptionPage.visitMyProfile.en,
        liveSessions: branding.receptionPage.liveSessions.en,
        lobbyText: branding.receptionPage.lobbyText.en,
        speakers: branding.receptionPage.speakers.en,
        speakersNavLinkText: branding.receptionPage.speakersNavLinkText.en,
        featuredCompanies: branding.receptionPage.featuredCompanies.en,
        liveSessionsNavLinkText: branding.receptionPage.liveSessionsNavLinkText.en,
        virtualCaffeNavLinkText: branding.receptionPage.virtualCaffeNavLinkText.en,
        showFloorNavLinkText: branding.receptionPage.showFloorNavLinkText.en,
        networkingNavLinkText: branding.receptionPage.networkingNavLinkText.en,
        attendes: branding.receptionPage.attendes.en,
        sessions: branding.receptionPage.sessions.en,
        viewAll: branding.receptionPage.viewAll.en,
        mySchedule: branding.receptionPage.mySchedule.en,
        exhibitors: branding.receptionPage.exhibitors.en,
        noEventsScheduled: branding.receptionPage.noEventsScheduled.en,
        noBookmarkedSpeakers: branding.receptionPage.noBookmarkedSpeakers.en,
        noBookmarkedExhibitors: branding.receptionPage.noBookmarkedExhibitors.en
      },
      bookmarkedSpeakers: {
        bookmarkedSpeakersTitle: branding.bookmarkedSpeakers.bookmarkedSpeakersTitle.en,
        bookmarkedSpeakersSearchPlaceholder: branding.bookmarkedSpeakers.bookmarkedSpeakersSearchPlaceholder.en,
      },
      bookmarkedExhibitors: {
        bookmarkedExhibitorsTitle: branding.bookmarkedExhibitors.bookmarkedExhibitorsTitle.en,
        bookmarkedExhibitorsSearchPlaceholder: branding.bookmarkedExhibitors.bookmarkedExhibitorsSearchPlaceholder.en,
      },
      conferenceTexts: {
        noAttendees: branding.conferenceTexts.noAttendees.en,
        mute: branding.conferenceTexts.mute.en,
        unmute: branding.conferenceTexts.unmute.en,
        soundOn: branding.conferenceTexts.soundOn.en,
        soundOff: branding.conferenceTexts.soundOff.en,
        videoOn: branding.conferenceTexts.videoOn.en,
        videoOff: branding.conferenceTexts.videoOff.en,
        shareScreenStart: branding.conferenceTexts.shareScreenStart.en,
        shareScreenStop: branding.conferenceTexts.shareScreenStop.en,
        leave: branding.conferenceTexts.leave.en,
        acceptCall: branding.conferenceTexts.acceptCall.en,
        declineCall: branding.conferenceTexts.declineCall.en,
        cancelCall: branding.conferenceTexts.cancelCall.en,
        settings: branding.conferenceTexts.settings.en,
        changingRoomConfirmationTitle: branding.conferenceTexts.changingRoomConfirmationTitle.en,
        changingRoomConfirmationText: branding.conferenceTexts.changingRoomConfirmationText.en,
      },
      meetingRoomGroups: [
        {
          id: branding.meetingRoomGroups[0].id,
          title: branding.meetingRoomGroups[0].title.en,
          organizationName: branding.meetingRoomGroups[0].organizationName?.en,
          organizationId: branding.meetingRoomGroups[0].organizationId,
          description: branding.meetingRoomGroups[0].description?.en,
          pictureUrl: branding.meetingRoomGroups[0].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[0].meetingRooms[0].id,
              title: branding.meetingRoomGroups[0].meetingRooms[0].title.en,
              description: branding.meetingRoomGroups[0].meetingRooms[0].description?.en,
              pictureUrl: branding.meetingRoomGroups[0].meetingRooms[0].pictureUrl,
            },
            {
              id: branding.meetingRoomGroups[0].meetingRooms[1].id,
              title: branding.meetingRoomGroups[0].meetingRooms[1].title.en,
              description: branding.meetingRoomGroups[0].meetingRooms[1].description?.en,
              pictureUrl: branding.meetingRoomGroups[0].meetingRooms[1].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[1].id,
          title: branding.meetingRoomGroups[1].title.en,
          organizationName: branding.meetingRoomGroups[1].organizationName?.en,
          organizationId: branding.meetingRoomGroups[1].organizationId,
          description: branding.meetingRoomGroups[1].description?.en,
          pictureUrl: branding.meetingRoomGroups[1].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[1].meetingRooms[0].id,
              title: branding.meetingRoomGroups[1].meetingRooms[0].title.en,
              description: branding.meetingRoomGroups[1].meetingRooms[0].description?.en,
              pictureUrl: branding.meetingRoomGroups[1].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[1].meetingRooms[1].id,
              title: branding.meetingRoomGroups[1].meetingRooms[1].title.en,
              description: branding.meetingRoomGroups[1].meetingRooms[1].description?.en,
              pictureUrl: branding.meetingRoomGroups[1].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[1].meetingRooms[2].id,
              title: branding.meetingRoomGroups[1].meetingRooms[2].title.en,
              description: branding.meetingRoomGroups[1].meetingRooms[2].description?.en,
              pictureUrl: branding.meetingRoomGroups[1].meetingRooms[2].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[2].id,
          title: branding.meetingRoomGroups[2].title.en,
          organizationName: branding.meetingRoomGroups[2].organizationName?.en,
          organizationId: branding.meetingRoomGroups[2].organizationId,
          description: branding.meetingRoomGroups[2].description?.en,
          pictureUrl: branding.meetingRoomGroups[2].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[2].meetingRooms[0].id,
              title: branding.meetingRoomGroups[2].meetingRooms[0].title.en,
              description: branding.meetingRoomGroups[2].meetingRooms[0].description?.en,
              pictureUrl: branding.meetingRoomGroups[2].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[2].meetingRooms[1].id,
              title: branding.meetingRoomGroups[2].meetingRooms[1].title.en,
              description: branding.meetingRoomGroups[2].meetingRooms[1].description?.en,
              pictureUrl: branding.meetingRoomGroups[2].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[2].meetingRooms[2].id,
              title: branding.meetingRoomGroups[2].meetingRooms[2].title.en,
              description: branding.meetingRoomGroups[2].meetingRooms[2].description?.en,
              pictureUrl: branding.meetingRoomGroups[2].meetingRooms[2].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[2].meetingRooms[3].id,
              title: branding.meetingRoomGroups[2].meetingRooms[3].title.en,
              description: branding.meetingRoomGroups[2].meetingRooms[3].description?.en,
              pictureUrl: branding.meetingRoomGroups[2].meetingRooms[3].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[3].id,
          title: branding.meetingRoomGroups[3].title.en,
          organizationName: branding.meetingRoomGroups[3].organizationName?.en,
          organizationId: branding.meetingRoomGroups[3].organizationId,
          description: branding.meetingRoomGroups[3].description?.en,
          pictureUrl: branding.meetingRoomGroups[3].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[3].meetingRooms[0].id,
              title: branding.meetingRoomGroups[3].meetingRooms[0].title.en,
              description: branding.meetingRoomGroups[3].meetingRooms[0].description?.en,
              pictureUrl: branding.meetingRoomGroups[3].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[3].meetingRooms[1].id,
              title: branding.meetingRoomGroups[3].meetingRooms[1].title.en,
              description: branding.meetingRoomGroups[3].meetingRooms[1].description?.en,
              pictureUrl: branding.meetingRoomGroups[3].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[3].meetingRooms[2].id,
              title: branding.meetingRoomGroups[3].meetingRooms[2].title.en,
              description: branding.meetingRoomGroups[3].meetingRooms[2].description?.en,
              pictureUrl: branding.meetingRoomGroups[3].meetingRooms[2].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[3].meetingRooms[3].id,
              title: branding.meetingRoomGroups[3].meetingRooms[3].title.en,
              description: branding.meetingRoomGroups[3].meetingRooms[3].description?.en,
              pictureUrl: branding.meetingRoomGroups[3].meetingRooms[3].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[4].id,
          title: branding.meetingRoomGroups[4].title.en,
          organizationName: branding.meetingRoomGroups[4].organizationName?.en,
          organizationId: branding.meetingRoomGroups[4].organizationId,
          description: branding.meetingRoomGroups[4].description?.en,
          pictureUrl: branding.meetingRoomGroups[4].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[4].meetingRooms[0].id,
              title: branding.meetingRoomGroups[4].meetingRooms[0].title.en,
              description: branding.meetingRoomGroups[4].meetingRooms[0].description?.en,
              pictureUrl: branding.meetingRoomGroups[4].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[4].meetingRooms[1].id,
              title: branding.meetingRoomGroups[4].meetingRooms[1].title.en,
              description: branding.meetingRoomGroups[4].meetingRooms[1].description?.en,
              pictureUrl: branding.meetingRoomGroups[4].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[4].meetingRooms[2].id,
              title: branding.meetingRoomGroups[4].meetingRooms[2].title.en,
              description: branding.meetingRoomGroups[4].meetingRooms[2].description?.en,
              pictureUrl: branding.meetingRoomGroups[4].meetingRooms[2].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[4].meetingRooms[3].id,
              title: branding.meetingRoomGroups[4].meetingRooms[3].title.en,
              description: branding.meetingRoomGroups[4].meetingRooms[3].description?.en,
              pictureUrl: branding.meetingRoomGroups[4].meetingRooms[3].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[5].id,
          title: branding.meetingRoomGroups[5].title.en,
          organizationName: branding.meetingRoomGroups[5].organizationName?.en,
          organizationId: branding.meetingRoomGroups[5].organizationId,
          description: branding.meetingRoomGroups[5].description?.en,
          pictureUrl: branding.meetingRoomGroups[5].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[5].meetingRooms[0].id,
              title: branding.meetingRoomGroups[5].meetingRooms[0].title.en,
              description: branding.meetingRoomGroups[5].meetingRooms[0].description?.en,
              pictureUrl: branding.meetingRoomGroups[5].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[5].meetingRooms[1].id,
              title: branding.meetingRoomGroups[5].meetingRooms[1].title.en,
              description: branding.meetingRoomGroups[5].meetingRooms[1].description?.en,
              pictureUrl: branding.meetingRoomGroups[5].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[5].meetingRooms[2].id,
              title: branding.meetingRoomGroups[5].meetingRooms[2].title.en,
              description: branding.meetingRoomGroups[5].meetingRooms[2].description?.en,
              pictureUrl: branding.meetingRoomGroups[5].meetingRooms[2].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[5].meetingRooms[3].id,
              title: branding.meetingRoomGroups[5].meetingRooms[3].title.en,
              description: branding.meetingRoomGroups[5].meetingRooms[3].description?.en,
              pictureUrl: branding.meetingRoomGroups[5].meetingRooms[3].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[6].id,
          title: branding.meetingRoomGroups[6].title.en,
          organizationName: branding.meetingRoomGroups[6].organizationName?.en,
          organizationId: branding.meetingRoomGroups[6].organizationId,
          description: branding.meetingRoomGroups[6].description?.en,
          pictureUrl: branding.meetingRoomGroups[6].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[6].meetingRooms[0].id,
              title: branding.meetingRoomGroups[6].meetingRooms[0].title.en,
              description: branding.meetingRoomGroups[6].meetingRooms[0].description?.en,
              pictureUrl: branding.meetingRoomGroups[6].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[6].meetingRooms[1].id,
              title: branding.meetingRoomGroups[6].meetingRooms[1].title.en,
              description: branding.meetingRoomGroups[6].meetingRooms[1].description?.en,
              pictureUrl: branding.meetingRoomGroups[6].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[6].meetingRooms[2].id,
              title: branding.meetingRoomGroups[6].meetingRooms[2].title.en,
              description: branding.meetingRoomGroups[6].meetingRooms[2].description?.en,
              pictureUrl: branding.meetingRoomGroups[6].meetingRooms[2].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[6].meetingRooms[3].id,
              title: branding.meetingRoomGroups[6].meetingRooms[3].title.en,
              description: branding.meetingRoomGroups[6].meetingRooms[3].description?.en,
              pictureUrl: branding.meetingRoomGroups[6].meetingRooms[3].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[7].id,
          title: branding.meetingRoomGroups[7].title.en,
          organizationName: branding.meetingRoomGroups[7].organizationName?.en,
          organizationId: branding.meetingRoomGroups[7].organizationId,
          description: branding.meetingRoomGroups[7].description?.en,
          pictureUrl: branding.meetingRoomGroups[7].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[7].meetingRooms[0].id,
              title: branding.meetingRoomGroups[7].meetingRooms[0].title.en,
              description: branding.meetingRoomGroups[7].meetingRooms[0].description?.en,
              pictureUrl: branding.meetingRoomGroups[7].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[7].meetingRooms[1].id,
              title: branding.meetingRoomGroups[7].meetingRooms[1].title.en,
              description: branding.meetingRoomGroups[7].meetingRooms[1].description?.en,
              pictureUrl: branding.meetingRoomGroups[7].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[7].meetingRooms[2].id,
              title: branding.meetingRoomGroups[7].meetingRooms[2].title.en,
              description: branding.meetingRoomGroups[7].meetingRooms[2].description?.en,
              pictureUrl: branding.meetingRoomGroups[7].meetingRooms[2].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[7].meetingRooms[3].id,
              title: branding.meetingRoomGroups[7].meetingRooms[3].title.en,
              description: branding.meetingRoomGroups[7].meetingRooms[3].description?.en,
              pictureUrl: branding.meetingRoomGroups[7].meetingRooms[3].pictureUrl,
            },
          ]
        },
      ],
      errorSite: {
        reloadButton: branding.errorSite.reloadButton.en,
        errorTitle: branding.errorSite.errorTitle.en,
        errorDescription: branding.errorSite.errorDescription.en,
      },
      communicationArea: {
        communicationAreaTitle: branding.communicationArea.communicationAreaTitle.en,
        reloadButtonText: branding.communicationArea.reloadButtonText.en,
        relevantListErrorMessage: branding.communicationArea.relevantListErrorMessage.en,
        requestListErrorMessage: branding.communicationArea.requestListErrorMessage.en,
        contactListErrorMessage: branding.communicationArea.contactListErrorMessage.en,
        chatListErrorMessage: branding.communicationArea.chatListErrorMessage.en,
        chatErrorMessage: branding.communicationArea.chatErrorMessage.en,
        chatMessageInputHint: branding.communicationArea.chatMessageInputHint.en,
        chatToolFavorite: branding.communicationArea.chatToolFavorite.en,
        chatToolCalendarEntry: branding.communicationArea.chatToolCalendarEntry.en,
        chatToolVideoChat: branding.communicationArea.chatToolVideoChat.en,
        chatToolRecommend: branding.communicationArea.chatToolRecommend.en,
        chatToolMute: branding.communicationArea.chatToolMute.en,
        chatToolUnmute: branding.communicationArea.chatToolUnmute.en,
        chatToolBlock: branding.communicationArea.chatToolBlock.en,
        relevantTabEmpty: branding.communicationArea.relevantTabEmpty.en,
        requestsTabEmpty: branding.communicationArea.requestsTabEmpty.en,
        contactsTabEmpty: branding.communicationArea.contactsTabEmpty.en,
        allChatsTabEmpty: branding.communicationArea.allChatsTabEmpty.en,
        contactChatsTabEmpty: branding.communicationArea.contactChatsTabEmpty.en,
        relevantTabTitle: branding.communicationArea.relevantTabTitle.en,
        requestsTabTitle: branding.communicationArea.requestsTabTitle.en,
        contactsTabTitle: branding.communicationArea.contactsTabTitle.en,
        chatTabTitle: branding.communicationArea.chatTabTitle.en,
        peopleTabTitle: branding.communicationArea.peopleTabTitle.en,
        callsTabTitle: branding.communicationArea.callsTabTitle.en,
        allCallsTabTitle: branding.communicationArea.allCallsTabTitle.en,
        missedCallsTabTitle: branding.communicationArea.missedCallsTabTitle.en,
        scheduleTabTitle: branding.communicationArea.scheduleTabTitle.en,
        allPeopleTabTitle: branding.communicationArea.allPeopleTabTitle.en,
        allChatsTabTitle: branding.communicationArea.allChatsTabTitle.en,
        contactChatsTabTitle: branding.communicationArea.contactChatsTabTitle.en,
        blockedChatsTabTitle: branding.communicationArea.blockedChatsTabTitle.en,
        allScheduleTabTitle: branding.communicationArea.allScheduleTabTitle.en,
        pendingScheduleTabTitle: branding.communicationArea.pendingScheduleTabTitle.en,
        declinedScheduleTabTitle: branding.communicationArea.declinedScheduleTabTitle.en,
        silentChat: branding.communicationArea.silentChat.en,
        settingsTitle: branding.communicationArea.settingsTitle.en,
        closeSettingsTitle: branding.communicationArea.closeSettingsTitle.en,
        onSign: branding.communicationArea.onSign.en,
        offSign: branding.communicationArea.offSign.en,
        chatOnOffTitle: branding.communicationArea.chatOnOffTitle.en,
        audioVideoActivateTitle: branding.communicationArea.audioVideoActivateTitle.en,
        noSearchResultsTitle: branding.communicationArea.noSearchResultsTitle.en,
        scheduleTabEmpty: branding.communicationArea.scheduleTabEmpty.en,
        scheduleTabNoResult: branding.communicationArea.scheduleTabNoResult.en,
        scheduleListSectionHeaderAccepted: branding.communicationArea.scheduleListSectionHeaderAccepted.en,
        scheduleListSectionHeaderPast: branding.communicationArea.scheduleListSectionHeaderPast.en,
        scheduleListSectionHeaderRequests: branding.communicationArea.scheduleListSectionHeaderRequests.en,
        scheduleListSectionHeaderDeclined: branding.communicationArea.scheduleListSectionHeaderDeclined.en,
        personCompanyLink: branding.communicationArea.personCompanyLink.en,
        blockedContactsTitle: branding.communicationArea.blockedContactsTitle.en,
        logOutTitle: branding.communicationArea.logOutTitle.en,
        livechat: branding.communicationArea.livechat.en,
        poll: branding.communicationArea.poll.en,
        qanda: branding.communicationArea.qanda.en,
        relevantTabRelevantSectionTitle: branding.communicationArea.relevantTabRelevantSectionTitle.en,
        relevantTabAllSectionTitle: branding.communicationArea.relevantTabAllSectionTitle.en,
        requestsTabAwaitingReplySectionTitle: branding.communicationArea.requestsTabAwaitingReplySectionTitle.en,
        requestsTabMyPendingRequestsSectionTitle: branding.communicationArea.requestsTabMyPendingRequestsSectionTitle.en,
        requestsTabMyOrganizationRequestsSectionTitle: branding.communicationArea.requestsTabMyOrganizationRequestsSectionTitle.en,
        requestsTabMyExhibitorRequestsSectionTitle: branding.communicationArea.requestsTabMyExhibitorRequestsSectionTitle.en,
        contactsTabFavoritesSectionTitle: branding.communicationArea.contactsTabFavoritesSectionTitle.en,
        contactsTabMyPersonalContactsSectionTitle: branding.communicationArea.contactsTabMyPersonalContactsSectionTitle.en,
        contactsTabMyOrganizationsContactsSectionTitle: branding.communicationArea.contactsTabMyOrganizationsContactsSectionTitle.en,
        requestAcceptTitle: branding.communicationArea.requestAcceptTitle.en,
        requestIgnoreTitle: branding.communicationArea.requestIgnoreTitle.en,
        requestDeclineTitle: branding.communicationArea.requestDeclineTitle.en,
        exportVCards: branding.communicationArea.exportVCards.en,
        exportICals: branding.communicationArea.exportICals.en,
      },
      contactEntry: {
        sendMessageTitle: branding.contactEntry.sendMessageTitle.en,
        startCallTitle: branding.contactEntry.startCallTitle.en,
        inviteToCallTitle: branding.contactEntry.inviteToCallTitle.de,
        requestMeetingTitle: branding.contactEntry.requestMeetingTitle.en,
        blockPersonTitle: branding.contactEntry.blockPersonTitle.en,
        bookmarkTitle: branding.contactEntry.bookmarkTitle.en,
        unbookmarkTitle: branding.contactEntry.unbookmarkTitle.en,
        sayHelloTitle: branding.contactEntry.sayHelloTitle.en,
        downloadVCardTitle: branding.contactEntry.downloadVCardTitle.en,
        cancelRequestMessage: branding.contactEntry.cancelRequestMessage.en,
        cancelConnectionMessage: branding.contactEntry.cancelConnectionMessage.en,
        ignoredMessage: branding.contactEntry.ignoredMessage.en,
        openRequestMessage: branding.contactEntry.openRequestMessage.en,
        sendRequestMessage: branding.contactEntry.sendRequestMessage.en,
        menuOptionsTitle: branding.contactEntry.menuOptionsTitle.en,
        expoUserBadgeTitle: branding.contactEntry.expoUserBadgeTitle.en,
        speakerUserBadgeTitle: branding.contactEntry.speakerUserBadgeTitle.en,
        visitorUserBadgeTitle: branding.contactEntry.visitorUserBadgeTitle.en
      },
      sideIconBar: {
        searchIconText: branding.sideIconBar.searchIconText.en,
        homeIconText: branding.sideIconBar.homeIconText.en,
        conferenceIconText: branding.sideIconBar.conferenceIconText.en,
        seminarsIconText: branding.sideIconBar.seminarsIconText.en,
        chatroomsIconText: branding.sideIconBar.chatroomsIconText.en,
        networkIconText: branding.sideIconBar.networkIconText.en,
        expoIconText: branding.sideIconBar.expoIconText.en,
        bookmarksIconText: branding.sideIconBar.bookmarksIconText.en,
        bookmarksTooltip: branding.sideIconBar.bookmarksTooltip.en,
        historyIconText: branding.sideIconBar.historyIconText.en,
        imprintIconText: branding.sideIconBar.imprintIconText.en,
        helpIconText: branding.sideIconBar.helpIconText.en,
        profileIconText: branding.sideIconBar.profileIconText.en,
        searchBarPlaceholder: branding.sideIconBar.searchBarPlaceholder.en,
        lobbyMenuText: branding.sideIconBar.lobbyMenuText.en,
        liveMenuText: branding.sideIconBar.liveMenuText.en,
        masterclassesMenuText: branding.sideIconBar.masterclassesMenuText.en,
        programMenuText: branding.sideIconBar.programMenuText.en,
        virtualcafeMenuText: branding.sideIconBar.virtualcafeMenuText.en,
        networkingMenuText: branding.sideIconBar.networkingMenuText.en,
        mediapartnersMenuText: branding.sideIconBar.mediapartnersMenuText.en,
        showfloorMenuText: branding.sideIconBar.showfloorMenuText.en,
        experiencesMenuText: branding.sideIconBar.experiencesMenuText.en,
        goodiebagMenuText: branding.sideIconBar.goodiebagMenuText.en,
        companiesSearchDrawerText: branding.sideIconBar.companiesSearchDrawerText.en,
        peopleSearchDrawerText: branding.sideIconBar.peopleSearchDrawerText.en,
        programSearchDrawerText: branding.sideIconBar.programSearchDrawerText.en,
        productsSearchDrawerText: branding.sideIconBar.productsSearchDrawerText.en,
        trademarksSearchDrawerText: branding.sideIconBar.trademarksSearchDrawerText.en,
        newsSearchDrawerText: branding.sideIconBar.newsSearchDrawerText.en,
        viewAllCompaniesSearchDrawerText: branding.sideIconBar.viewAllCompaniesSearchDrawerText.en,
        viewAllPeopleSearchDrawerText: branding.sideIconBar.viewAllPeopleSearchDrawerText.en,
        viewAllProgramSearchDrawerText: branding.sideIconBar.viewAllProgramSearchDrawerText.en,
        viewAllProductsSearchDrawerText: branding.sideIconBar.viewAllProductsSearchDrawerText.en,
        viewAllTrademarksSearchDrawerText: branding.sideIconBar.viewAllTrademarksSearchDrawerText.en,
        viewAllNewsSearchDrawerText: branding.sideIconBar.viewAllNewsSearchDrawerText.en,
      },
      navigationArea: {
        speakersItemTitle: branding.navigationArea.speakersItemTitle.en,
        productsItemTitle: branding.navigationArea.productsItemTitle.en,
        trademarksItemTitle: branding.navigationArea.trademarksItemTitle.en,
        newsItemTitle: branding.navigationArea.newsItemTitle.en,
        sponsorsItemTitle: branding.navigationArea.sponsorsItemTitle.en,
        sessionsItemTitle: branding.navigationArea.sessionsItemTitle.en,
        liveSessionsItemTitle: branding.navigationArea.liveSessionsItemTitle.en,
        videoOnDemandItemTitle: branding.navigationArea.videoOnDemandItemTitle.en,
        futureCafeItemTitle: branding.navigationArea.futureCafeItemTitle.en,
        businessCafeItemTitle: branding.navigationArea.businessCafeItemTitle.en,
        marketingCafeItemTitle: branding.navigationArea.marketingCafeItemTitle.en,
        technologyCafeItemTitle: branding.navigationArea.technologyCafeItemTitle.en,
        networkingItemTitle: branding.navigationArea.networkingItemTitle.en,
        companiesItemTitle: branding.navigationArea.companiesItemTitle.en,
        peopleItemTitle: branding.navigationArea.peopleItemTitle.en,
        eventdateItemTitle: branding.navigationArea.eventdateItemTitle.en,
        scheduleItemTitle: branding.navigationArea.scheduleItemTitle.en,
        presenceItemTitle: branding.navigationArea.presenceItemTitle.en,
        themesItemTitle: branding.navigationArea.themesItemTitle.en,
        themeItemTitle: branding.navigationArea.themeItemTitle.en,
        presenceTypes: {
          availableState: branding.navigationArea.presenceTypes.availableState.en,
          busyState: branding.navigationArea.presenceTypes.busyState.en,
          doNotDisturbState: branding.navigationArea.presenceTypes.doNotDisturbState.en,
          offWork: branding.navigationArea.presenceTypes.offWork.en
        },
        signOutTitle: branding.navigationArea.signOutTitle.en
      },
      sponsorsPageContent: {
        sponsorsHeader: branding.sponsorsPageContent.sponsorsHeader.en,
        moreSponsorsInfo: branding.sponsorsPageContent.moreSponsorsInfo.en
      },
      sessionsSchedule: {
        noBookmarksMessage: branding.sessionsSchedule.noBookmarksMessage.en,
        noDataMessage: branding.sessionsSchedule.noDataMessage.en
      },
      sessionsLive: {
        noBookmarksMessage: branding.sessionsLive.noBookmarksMessage.en,
        noDataMessage: branding.sessionsLive.noDataMessage.en
      },
      eventDateTimeTable: {
        searchBarPlaceholder: branding.eventDateTimeTable.searchBarPlaceholder.en,
        sunday: branding.eventDateTimeTable.sunday.en,
        monday: branding.eventDateTimeTable.monday.en,
        tuesday: branding.eventDateTimeTable.tuesday.en,
        wednesday: branding.eventDateTimeTable.wednesday.en,
        thursday: branding.eventDateTimeTable.thursday.en,
        friday: branding.eventDateTimeTable.friday.en,
        saturday: branding.eventDateTimeTable.saturday.en,
        noBookmarksMessage: branding.eventDateTimeTable.noBookmarksMessage.en,
        noDataMessage: branding.eventDateTimeTable.noDataMessage.en
      },
      calendarEntryModalPageContent: {
        createTitle: branding.calendarEntryModalPageContent.createTitle.en,
        editTitle: branding.calendarEntryModalPageContent.editTitle.en,
        name: branding.calendarEntryModalPageContent.name.en,
        namePlaceholder: branding.calendarEntryModalPageContent.namePlaceholder.en,
        searchPerson: branding.calendarEntryModalPageContent.searchPerson.en,
        searchPersonPlaceholder: branding.calendarEntryModalPageContent.searchPersonPlaceholder.en,
        notFound: branding.calendarEntryModalPageContent.notFound.en,
        startDatePlaceholder: branding.calendarEntryModalPageContent.startDatePlaceholder.en,
        endDatePlaceholder: branding.calendarEntryModalPageContent.endDatePlaceholder.en,
        day: branding.calendarEntryModalPageContent.day.en,
        dayPlaceholder: branding.calendarEntryModalPageContent.dayPlaceholder.en,
        notes: branding.calendarEntryModalPageContent.notes.en,
        notesPlaceholder: branding.calendarEntryModalPageContent.notesPlaceholder.en,
        sendInvite: branding.calendarEntryModalPageContent.sendInvite.en,
        saveChanges: branding.calendarEntryModalPageContent.saveChanges.en,
        deleteMeeting: branding.calendarEntryModalPageContent.deleteMeeting.en,
        dayFormat: branding.calendarEntryModalPageContent.dayFormat.en
      },
      calendarEntryListView: {
        editButtonTitle: branding.calendarEntryListView.editButtonTitle.en,
        viewButtonTitle: branding.calendarEntryListView.viewButtonTitle.en,
        deleteButtonTitle: branding.calendarEntryListView.deleteButtonTitle.en,
        callButtonTitle: branding.calendarEntryListView.callButtonTitle.en,
        chatButtonTitle: branding.calendarEntryListView.chatButtonTitle.en,
        exportButtonTitle: branding.calendarEntryListView.exportButtonTitle.en,
        declineButtonTitle: branding.calendarEntryListView.declineButtonTitle.en,
        acceptButtonTitle: branding.calendarEntryListView.acceptButtonTitle.en,
        declineTitle: branding.calendarEntryListView.declineTitle.en,
        acceptTitle: branding.calendarEntryListView.acceptTitle.en,
        dateTo: branding.calendarEntryListView.dateTo.en,
        dateHourFormat: branding.calendarEntryListView.dateHourFormat.en,
        dateDayFormat: branding.calendarEntryListView.dateDayFormat.en
      },
      calendarEntryExport: {
        organizer: branding.calendarEntryExport.organizer.en,
        attendees: branding.calendarEntryExport.attendees.en
      },
      productsPageContent: {
        navigationSearchPlaceholderProducts: branding.productsPageContent.navigationSearchPlaceholderProducts.en,
        loadingMessage: branding.productsPageContent.loadingMessage.en,
        noBookmarksMessage: branding.productsPageContent.noBookmarksMessage.en
      },
      trademarksPageContent: {
        navigationSearchPlaceholderTrademarks: branding.trademarksPageContent.navigationSearchPlaceholderTrademarks.en,
        loadingMessage: branding.trademarksPageContent.loadingMessage.en,
        noBookmarksMessage: branding.trademarksPageContent.noBookmarksMessage.en
      },
      exhibitorsPageContent: {
        navigationSearchPlaceholderCompanies: branding.exhibitorsPageContent.navigationSearchPlaceholderCompanies.en,
        loadingMessage: branding.exhibitorsPageContent.loadingMessage.en,
        noBookmarksMessage: branding.exhibitorsPageContent.noBookmarksMessage.en,
        onlyShowBookmarksTitle: branding.programSchedule.onlyShowBookmarksTitle.en,
      },
      myProfilePageContent: {
        networkingTitle: branding.myProfilePageContent.networkingTitle.en,
        firstnamePlaceholder: branding.myProfilePageContent.firstnamePlaceholder.en,
        lastnamePlaceholder: branding.myProfilePageContent.lastnamePlaceholder.en,
        companyPlaceholder: branding.myProfilePageContent.companyPlaceholder.en,
        jobtitlePlaceholder: branding.myProfilePageContent.jobtitlePlaceholder.en,
        responsibilityPlaceholder: branding.myProfilePageContent.responsibilityPlaceholder.en,
        interestsPlaceholder: branding.myProfilePageContent.interestsPlaceholder.en,
        mobilePlaceholder: branding.myProfilePageContent.mobilePlaceholder.en,
        numberPlaceholder: branding.myProfilePageContent.numberPlaceholder.en,
        profileinfoPlaceholder: branding.myProfilePageContent.profileinfoPlaceholder.en,
        updateDataButtonText: branding.myProfilePageContent.updateDataButtonText.en
      },
      personDetailPageContent: {
        navbarText: branding.personDetailPageContent.navbarText.en,
        cancelRequestMessage: branding.personDetailPageContent.cancelRequestMessage.en,
        cancelConnectionMessage: branding.personDetailPageContent.cancelConnectionMessage.en,
        ignoredMessage: branding.personDetailPageContent.ignoredMessage.en,
        openRequestMessage: branding.personDetailPageContent.openRequestMessage.en,
        sendRequestMessage: branding.personDetailPageContent.sendRequestMessage.en,
        chatText: branding.personDetailPageContent.chatText.en,
        videoCallText: branding.personDetailPageContent.videoCallText.en,
        voiceCallText: branding.personDetailPageContent.voiceCallText.en,
        voiceCallingText: branding.personDetailPageContent.voiceCallingText.en,
        meetingText: branding.personDetailPageContent.meetingText.en,
      },
      sayHelloModal: {
        cancelRequestMessage: branding.sayHelloModal.cancelRequestMessage.en,
        cancelConnectionMessage: branding.sayHelloModal.cancelConnectionMessage.en,
        ignoredMessage: branding.sayHelloModal.ignoredMessage.en,
        openRequestMessage: branding.sayHelloModal.openRequestMessage.en,
        sendMessagePlaceholder: branding.sayHelloModal.sendMessagePlaceholder.en,
        headerTextSayHello: branding.sayHelloModal.headerTextSayHello.en,
        headerTextCancelRequest: branding.sayHelloModal.headerTextCancelRequest.en,
        submitTextAccept: branding.sayHelloModal.submitTextAccept.en,
        submitTextIgnore: branding.sayHelloModal.submitTextIgnore.en,
        submitTextYes: branding.sayHelloModal.submitTextYes.en,
        submitTextSend: branding.sayHelloModal.submitTextSend.en,
        submitTextClose: branding.sayHelloModal.submitTextClose.en,
      },
      filterBar: {
        allFilterTitle: branding.filterBar.allFilterTitle.en,
      },
      organizationDetailPageContent: {
        navbarTitle: branding.organizationDetailPageContent.navbarTitle.en,
        sectionLineContactPerson: branding.organizationDetailPageContent.sectionLineContactPerson.en,
        sectionLineCategories: branding.organizationDetailPageContent.sectionLineCategories.en,
        sectionLineProductDescription: branding.organizationDetailPageContent.sectionLineProductDescription.en,
        buttonShowMore: branding.organizationDetailPageContent.buttonShowMore.en,
        buttonCollapse: branding.organizationDetailPageContent.buttonCollapse.en
      },
      productDetailPageContent: {
        navbarText: branding.productDetailPageContent.navbarText.en
      },
      trademarkDetailPageContent: {
        navbarText: branding.trademarkDetailPageContent.navbarText.en
      },
      eventDateDetailPageContent: {
        personsTitle: branding.eventDateDetailPageContent.personsTitle.en,
        galleryTitle: branding.eventDateDetailPageContent.galleryTitle.en,
        noPersonsMessage: branding.eventDateDetailPageContent.noPersonsMessage.en,
      },
      infoPage: {
        management: {
          title: branding.infoPage.management.title.en
        },
        persons: {
          title: branding.infoPage.persons.title.en,
          item1: branding.infoPage.persons.item1.en,
          item2: branding.infoPage.persons.item2.en
        },
        headquarters: {
          title: branding.infoPage.headquarters.title.en
        },
        vatNumber: {
          title: branding.infoPage.vatNumber.title.en
        }
      },
      networkingArea: {
        filterByTypeText: branding.networkingArea.filterByTypeText.en,
        filterByPositionText: branding.networkingArea.filterByPositionText.en,
        showMyContactsText: branding.networkingArea.showMyContactsText.en,
        sayHelloText: branding.networkingArea.sayHelloText.en,
        showMoreText: branding.networkingArea.showMoreText.en,
        startVideoAudioChatText: branding.networkingArea.startVideoAudioChatText.en,
        startTextChat: branding.networkingArea.startTextChat.en,
        connectedText: branding.networkingArea.connectedText.en,
        loadMoreConnectionsText: branding.networkingArea.loadMoreConnectionsText.en,
        showTacticalViewText: branding.networkingArea.showTacticalViewText.en,
        showSecondGenText: branding.networkingArea.showSecondGenText.en,
        showRelevantConnectionsText: branding.networkingArea.showRelevantConnectionsText.en,
      },
      programPageContent: {
        programPageTitle: branding.programPageContent.programPageTitle.en,
        scheduleTabTitle: branding.programPageContent.scheduleTabTitle.en,
        speakersTabTitle: branding.programPageContent.speakersTabTitle.en,
      },
      programSchedule: {
        searchBarPlaceholder: branding.programSchedule.searchBarPlaceholder.en,
        sunday: branding.programSchedule.sunday.en,
        monday: branding.programSchedule.monday.en,
        tuesday: branding.programSchedule.tuesday.en,
        wednesday: branding.programSchedule.wednesday.en,
        thursday: branding.programSchedule.thursday.en,
        friday: branding.programSchedule.friday.en,
        saturday: branding.programSchedule.saturday.en,
        noBookmarksMessage: branding.programSchedule.noBookmarksMessage.en,
        noDataMessage: branding.programSchedule.noDataMessage.en,
        onlyShowBookmarksTitle: branding.programSchedule.onlyShowBookmarksTitle.en,
      },
      programSpeakers: {
        navigationSearchPlaceholderSpeakers: branding.programSpeakers.navigationSearchPlaceholderSpeakers.en,
        loadingMessage: branding.programSpeakers.loadingMessage.en,
        noBookmarksMessage: branding.programSpeakers.noBookmarksMessage.en,
        onlyShowBookmarksTitle: branding.programSpeakers.onlyShowBookmarksTitle.en,
      },
    },
    "de": {
      globalStatePopupTexts: {
        errorNoCameraPermission: branding.globalStatePopupTexts.errorNoCameraPermission.de,
        errorNoScreenCapturePermission: branding.globalStatePopupTexts.errorNoScreenCapturePermission.de
      },
      globalTexts: {
        usersOnline: branding.globalTexts.usersOnline.de,
        usersOnPage: branding.globalTexts.usersOnPage.de,
        privacyPolicyTitle: branding.globalTexts.privacyPolicyTitle.de,
        privacyPolicyText: branding.globalTexts.privacyPolicyText.de,
        privacyPolicyAgreeText: branding.globalTexts.privacyPolicyAgreeText.de,
        privacyPolicyDisagreeText: branding.globalTexts.privacyPolicyDisagreeText.de,
        confirm: branding.globalTexts.confirm.de,
        cancel: branding.globalTexts.cancel.de,
      },
      ticketSale: {
        getYourTicketButton: branding.ticketSale.getYourTicketButton.de,
        getYourTicketNowButton: branding.ticketSale.getYourTicketNowButton.de,
        leadDescriptionText: branding.ticketSale.leadDescriptionText.de,
        commingText: branding.ticketSale.commingText.de,
        checkYourSystemDescriptionText1: branding.ticketSale.checkYourSystemDescriptionText1.de,
        checkYourSystemDescriptionText2: branding.ticketSale.checkYourSystemDescriptionText2.de,
        checkYourSystemButton: branding.ticketSale.checkYourSystemButton.de,
        imprintLink: branding.ticketSale.imprintLink.de,
        imprintLinkUrl: branding.ticketSale.imprintLinkUrl.de,
        ticketsUrl: branding.ticketSale.ticketsUrl.de,
      },
      loginRegistrationSite: {
        submitEmailButton: branding.loginRegistrationSite.submitEmailButton.de,
        emailPlaceHolder: branding.loginRegistrationSite.emailPlaceHolder.de,
        passwordPlaceHolder: branding.loginRegistrationSite.passwordPlaceHolder.de,
        submitTokenButton: branding.loginRegistrationSite.submitTokenButton.de,
        tokenPlaceholder: branding.loginRegistrationSite.tokenPlaceholder.de,
        invalidToken: branding.loginRegistrationSite.invalidToken.de,
        wrongToken: branding.loginRegistrationSite.wrongToken.de,
        createNewToken: branding.loginRegistrationSite.createNewToken.de,
        noToken: branding.loginRegistrationSite.noToken.de,
        resendToken: branding.loginRegistrationSite.resendToken.de,
        ticketPanel: branding.loginRegistrationSite.ticketPanel.de,
        buyTicketButton: branding.loginRegistrationSite.buyTicketButton.de,
        changeEmailButton: branding.loginRegistrationSite.changeEmailButton.de,
        createTokenFailed: branding.loginRegistrationSite.createTokenFailed.de,
        createTokenSuccess: branding.loginRegistrationSite.createTokenSuccess.de,
        dataPrivacyPanel: branding.loginRegistrationSite.dataPrivacyPanel.de,
        dataPolicy: branding.loginRegistrationSite.dataPolicy.de,
        invalidEmailPanel: branding.loginRegistrationSite.invalidEmailPanel.de,
        outdatedPrivacyDocsPanel: branding.loginRegistrationSite.outdatedPrivacyDocsPanel.de,
        registerEmailFailed: branding.loginRegistrationSite.registerEmailFailed.de,
        wrongPasswordPanel: branding.loginRegistrationSite.wrongPasswordPanel.de,
        forgotPassword: branding.loginRegistrationSite.forgotPassword.de,
        enterEmail: branding.loginRegistrationSite.enterEmail.de,
        enterPassword: branding.loginRegistrationSite.enterPassword.de,
        enterToken: branding.loginRegistrationSite.enterToken.de,
        centerLoginStyleTitle: branding.loginRegistrationSite.centerLoginStyleTitle.de,
      },
      receptionPage: {
        receptionPageTitleDescription: branding.receptionPage.receptionPageTitleDescription.de,
        receptionPageLobbyHeaderTitle: branding.receptionPage.receptionPageLobbyHeaderTitle.de,
        receptionPageLobbyHeaderDescription: branding.receptionPage.receptionPageLobbyHeaderDescription.de,
        receptionPageMyHeaderTitle: branding.receptionPage.receptionPageMyHeaderTitle.de,
        receptionPageMyHeaderDescription: branding.receptionPage.receptionPageMyHeaderDescription.de,
        visitMyProfile: branding.receptionPage.visitMyProfile.en,
        liveSessions: branding.receptionPage.liveSessions.de,
        lobbyText: branding.receptionPage.lobbyText.de,
        speakers: branding.receptionPage.speakers.de,
        speakersNavLinkText: branding.receptionPage.speakersNavLinkText.en,
        featuredCompanies: branding.receptionPage.featuredCompanies.de,
        liveSessionsNavLinkText: branding.receptionPage.liveSessionsNavLinkText.en,
        virtualCaffeNavLinkText: branding.receptionPage.virtualCaffeNavLinkText.de,
        showFloorNavLinkText: branding.receptionPage.showFloorNavLinkText.en,
        networkingNavLinkText: branding.receptionPage.networkingNavLinkText.de,
        attendes: branding.receptionPage.attendes.de,
        sessions: branding.receptionPage.sessions.de,
        viewAll: branding.receptionPage.viewAll.de,
        mySchedule: branding.receptionPage.mySchedule.en,
        exhibitors: branding.receptionPage.exhibitors.en,
        noEventsScheduled: branding.receptionPage.noEventsScheduled.en,
        noBookmarkedSpeakers: branding.receptionPage.noBookmarkedSpeakers.en,
        noBookmarkedExhibitors: branding.receptionPage.noBookmarkedExhibitors.en
      },
      bookmarkedSpeakers: {
        bookmarkedSpeakersTitle: branding.bookmarkedSpeakers.bookmarkedSpeakersTitle.de,
        bookmarkedSpeakersSearchPlaceholder: branding.bookmarkedSpeakers.bookmarkedSpeakersSearchPlaceholder.de,
      },
      bookmarkedExhibitors: {
        bookmarkedExhibitorsTitle: branding.bookmarkedExhibitors.bookmarkedExhibitorsTitle.de,
        bookmarkedExhibitorsSearchPlaceholder: branding.bookmarkedExhibitors.bookmarkedExhibitorsSearchPlaceholder.de,
      },
      conferenceTexts: {
        noAttendees: branding.conferenceTexts.noAttendees.de,
        mute: branding.conferenceTexts.mute.de,
        unmute: branding.conferenceTexts.unmute.de,
        soundOn: branding.conferenceTexts.soundOn.de,
        soundOff: branding.conferenceTexts.soundOff.de,
        videoOn: branding.conferenceTexts.videoOn.de,
        videoOff: branding.conferenceTexts.videoOff.de,
        shareScreenStart: branding.conferenceTexts.shareScreenStart.de,
        shareScreenStop: branding.conferenceTexts.shareScreenStop.de,
        leave: branding.conferenceTexts.leave.de,
        acceptCall: branding.conferenceTexts.acceptCall.de,
        declineCall: branding.conferenceTexts.declineCall.de,
        cancelCall: branding.conferenceTexts.cancelCall.de,
        settings: branding.conferenceTexts.settings.de,
        changingRoomConfirmationTitle: branding.conferenceTexts.changingRoomConfirmationTitle.de,
        changingRoomConfirmationText: branding.conferenceTexts.changingRoomConfirmationText.de,
      },
      meetingRoomGroups: [
        {
          id: branding.meetingRoomGroups[0].id,
          title: branding.meetingRoomGroups[0].title.de,
          organizationName: branding.meetingRoomGroups[0].organizationName?.de,
          organizationId: branding.meetingRoomGroups[0].organizationId,
          description: branding.meetingRoomGroups[0].description?.de,
          pictureUrl: branding.meetingRoomGroups[0].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[0].meetingRooms[0].id,
              title: branding.meetingRoomGroups[0].meetingRooms[0].title.de,
              description: branding.meetingRoomGroups[0].meetingRooms[0].description?.de,
              pictureUrl: branding.meetingRoomGroups[0].meetingRooms[0].pictureUrl,
            },
            {
              id: branding.meetingRoomGroups[0].meetingRooms[1].id,
              title: branding.meetingRoomGroups[0].meetingRooms[1].title.de,
              description: branding.meetingRoomGroups[0].meetingRooms[1].description?.de,
              pictureUrl: branding.meetingRoomGroups[0].meetingRooms[1].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[1].id,
          title: branding.meetingRoomGroups[1].title.de,
          organizationName: branding.meetingRoomGroups[1].organizationName?.de,
          organizationId: branding.meetingRoomGroups[1].organizationId,
          description: branding.meetingRoomGroups[1].description?.de,
          pictureUrl: branding.meetingRoomGroups[1].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[1].meetingRooms[0].id,
              title: branding.meetingRoomGroups[1].meetingRooms[0].title.de,
              description: branding.meetingRoomGroups[1].meetingRooms[0].description?.de,
              pictureUrl: branding.meetingRoomGroups[1].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[1].meetingRooms[1].id,
              title: branding.meetingRoomGroups[1].meetingRooms[1].title.de,
              description: branding.meetingRoomGroups[1].meetingRooms[1].description?.de,
              pictureUrl: branding.meetingRoomGroups[1].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[1].meetingRooms[2].id,
              title: branding.meetingRoomGroups[1].meetingRooms[2].title.de,
              description: branding.meetingRoomGroups[1].meetingRooms[2].description?.de,
              pictureUrl: branding.meetingRoomGroups[1].meetingRooms[2].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[2].id,
          title: branding.meetingRoomGroups[2].title.de,
          organizationName: branding.meetingRoomGroups[2].organizationName?.de,
          organizationId: branding.meetingRoomGroups[2].organizationId,
          description: branding.meetingRoomGroups[2].description?.de,
          pictureUrl: branding.meetingRoomGroups[2].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[2].meetingRooms[0].id,
              title: branding.meetingRoomGroups[2].meetingRooms[0].title.de,
              description: branding.meetingRoomGroups[2].meetingRooms[0].description?.de,
              pictureUrl: branding.meetingRoomGroups[2].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[2].meetingRooms[1].id,
              title: branding.meetingRoomGroups[2].meetingRooms[1].title.de,
              description: branding.meetingRoomGroups[2].meetingRooms[1].description?.de,
              pictureUrl: branding.meetingRoomGroups[2].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[2].meetingRooms[2].id,
              title: branding.meetingRoomGroups[2].meetingRooms[2].title.de,
              description: branding.meetingRoomGroups[2].meetingRooms[2].description?.de,
              pictureUrl: branding.meetingRoomGroups[2].meetingRooms[2].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[2].meetingRooms[3].id,
              title: branding.meetingRoomGroups[2].meetingRooms[3].title.de,
              description: branding.meetingRoomGroups[2].meetingRooms[3].description?.de,
              pictureUrl: branding.meetingRoomGroups[2].meetingRooms[3].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[3].id,
          title: branding.meetingRoomGroups[3].title.de,
          organizationName: branding.meetingRoomGroups[3].organizationName?.de,
          organizationId: branding.meetingRoomGroups[3].organizationId,
          description: branding.meetingRoomGroups[3].description?.de,
          pictureUrl: branding.meetingRoomGroups[3].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[3].meetingRooms[0].id,
              title: branding.meetingRoomGroups[3].meetingRooms[0].title.de,
              description: branding.meetingRoomGroups[3].meetingRooms[0].description?.de,
              pictureUrl: branding.meetingRoomGroups[3].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[3].meetingRooms[1].id,
              title: branding.meetingRoomGroups[3].meetingRooms[1].title.de,
              description: branding.meetingRoomGroups[3].meetingRooms[1].description?.de,
              pictureUrl: branding.meetingRoomGroups[3].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[3].meetingRooms[2].id,
              title: branding.meetingRoomGroups[3].meetingRooms[2].title.de,
              description: branding.meetingRoomGroups[3].meetingRooms[2].description?.de,
              pictureUrl: branding.meetingRoomGroups[3].meetingRooms[2].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[3].meetingRooms[3].id,
              title: branding.meetingRoomGroups[3].meetingRooms[3].title.de,
              description: branding.meetingRoomGroups[3].meetingRooms[3].description?.de,
              pictureUrl: branding.meetingRoomGroups[3].meetingRooms[3].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[4].id,
          title: branding.meetingRoomGroups[4].title.de,
          organizationName: branding.meetingRoomGroups[4].organizationName?.de,
          organizationId: branding.meetingRoomGroups[4].organizationId,
          description: branding.meetingRoomGroups[4].description?.de,
          pictureUrl: branding.meetingRoomGroups[4].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[4].meetingRooms[0].id,
              title: branding.meetingRoomGroups[4].meetingRooms[0].title.de,
              description: branding.meetingRoomGroups[4].meetingRooms[0].description?.de,
              pictureUrl: branding.meetingRoomGroups[4].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[4].meetingRooms[1].id,
              title: branding.meetingRoomGroups[4].meetingRooms[1].title.de,
              description: branding.meetingRoomGroups[4].meetingRooms[1].description?.de,
              pictureUrl: branding.meetingRoomGroups[4].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[4].meetingRooms[2].id,
              title: branding.meetingRoomGroups[4].meetingRooms[2].title.de,
              description: branding.meetingRoomGroups[4].meetingRooms[2].description?.de,
              pictureUrl: branding.meetingRoomGroups[4].meetingRooms[2].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[4].meetingRooms[3].id,
              title: branding.meetingRoomGroups[4].meetingRooms[3].title.de,
              description: branding.meetingRoomGroups[4].meetingRooms[3].description?.de,
              pictureUrl: branding.meetingRoomGroups[4].meetingRooms[3].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[5].id,
          title: branding.meetingRoomGroups[5].title.de,
          organizationName: branding.meetingRoomGroups[5].organizationName?.de,
          organizationId: branding.meetingRoomGroups[5].organizationId,
          description: branding.meetingRoomGroups[5].description?.de,
          pictureUrl: branding.meetingRoomGroups[5].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[5].meetingRooms[0].id,
              title: branding.meetingRoomGroups[5].meetingRooms[0].title.de,
              description: branding.meetingRoomGroups[5].meetingRooms[0].description?.de,
              pictureUrl: branding.meetingRoomGroups[5].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[5].meetingRooms[1].id,
              title: branding.meetingRoomGroups[5].meetingRooms[1].title.de,
              description: branding.meetingRoomGroups[5].meetingRooms[1].description?.de,
              pictureUrl: branding.meetingRoomGroups[5].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[5].meetingRooms[2].id,
              title: branding.meetingRoomGroups[5].meetingRooms[2].title.de,
              description: branding.meetingRoomGroups[5].meetingRooms[2].description?.de,
              pictureUrl: branding.meetingRoomGroups[5].meetingRooms[2].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[5].meetingRooms[3].id,
              title: branding.meetingRoomGroups[5].meetingRooms[3].title.de,
              description: branding.meetingRoomGroups[5].meetingRooms[3].description?.de,
              pictureUrl: branding.meetingRoomGroups[5].meetingRooms[3].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[6].id,
          title: branding.meetingRoomGroups[6].title.de,
          organizationName: branding.meetingRoomGroups[6].organizationName?.de,
          organizationId: branding.meetingRoomGroups[6].organizationId,
          description: branding.meetingRoomGroups[6].description?.de,
          pictureUrl: branding.meetingRoomGroups[6].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[6].meetingRooms[0].id,
              title: branding.meetingRoomGroups[6].meetingRooms[0].title.de,
              description: branding.meetingRoomGroups[6].meetingRooms[0].description?.de,
              pictureUrl: branding.meetingRoomGroups[6].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[6].meetingRooms[1].id,
              title: branding.meetingRoomGroups[6].meetingRooms[1].title.de,
              description: branding.meetingRoomGroups[6].meetingRooms[1].description?.de,
              pictureUrl: branding.meetingRoomGroups[6].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[6].meetingRooms[2].id,
              title: branding.meetingRoomGroups[6].meetingRooms[2].title.de,
              description: branding.meetingRoomGroups[6].meetingRooms[2].description?.de,
              pictureUrl: branding.meetingRoomGroups[6].meetingRooms[2].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[6].meetingRooms[3].id,
              title: branding.meetingRoomGroups[6].meetingRooms[3].title.de,
              description: branding.meetingRoomGroups[6].meetingRooms[3].description?.de,
              pictureUrl: branding.meetingRoomGroups[6].meetingRooms[3].pictureUrl,
            },
          ]
        },
        {
          id: branding.meetingRoomGroups[7].id,
          title: branding.meetingRoomGroups[7].title.de,
          organizationName: branding.meetingRoomGroups[7].organizationName?.de,
          organizationId: branding.meetingRoomGroups[7].organizationId,
          description: branding.meetingRoomGroups[7].description?.de,
          pictureUrl: branding.meetingRoomGroups[7].pictureUrl,
          meetingRooms: [
            {
              id: branding.meetingRoomGroups[7].meetingRooms[0].id,
              title: branding.meetingRoomGroups[7].meetingRooms[0].title.de,
              description: branding.meetingRoomGroups[7].meetingRooms[0].description?.de,
              pictureUrl: branding.meetingRoomGroups[7].meetingRooms[0].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[7].meetingRooms[1].id,
              title: branding.meetingRoomGroups[7].meetingRooms[1].title.de,
              description: branding.meetingRoomGroups[7].meetingRooms[1].description?.de,
              pictureUrl: branding.meetingRoomGroups[7].meetingRooms[1].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[7].meetingRooms[2].id,
              title: branding.meetingRoomGroups[7].meetingRooms[2].title.de,
              description: branding.meetingRoomGroups[7].meetingRooms[2].description?.de,
              pictureUrl: branding.meetingRoomGroups[7].meetingRooms[2].pictureUrl,
            }, {
              id: branding.meetingRoomGroups[7].meetingRooms[3].id,
              title: branding.meetingRoomGroups[7].meetingRooms[3].title.de,
              description: branding.meetingRoomGroups[7].meetingRooms[3].description?.de,
              pictureUrl: branding.meetingRoomGroups[7].meetingRooms[3].pictureUrl,
            },
          ]
        },
      ],
      errorSite: {
        reloadButton: branding.errorSite.reloadButton.de,
        errorTitle: branding.errorSite.errorTitle.de,
        errorDescription: branding.errorSite.errorDescription.de,
      },
      communicationArea: {
        communicationAreaTitle: branding.communicationArea.communicationAreaTitle.de,
        reloadButtonText: branding.communicationArea.reloadButtonText.de,
        relevantListErrorMessage: branding.communicationArea.relevantListErrorMessage.de,
        requestListErrorMessage: branding.communicationArea.requestListErrorMessage.de,
        contactListErrorMessage: branding.communicationArea.contactListErrorMessage.de,
        chatListErrorMessage: branding.communicationArea.chatListErrorMessage.de,
        chatErrorMessage: branding.communicationArea.chatErrorMessage.de,
        chatMessageInputHint: branding.communicationArea.chatMessageInputHint.de,
        chatToolFavorite: branding.communicationArea.chatToolFavorite.de,
        chatToolCalendarEntry: branding.communicationArea.chatToolCalendarEntry.de,
        chatToolVideoChat: branding.communicationArea.chatToolVideoChat.de,
        chatToolRecommend: branding.communicationArea.chatToolRecommend.de,
        chatToolMute: branding.communicationArea.chatToolMute.de,
        chatToolUnmute: branding.communicationArea.chatToolUnmute.de,
        chatToolBlock: branding.communicationArea.chatToolBlock.de,
        relevantTabEmpty: branding.communicationArea.relevantTabEmpty.de,
        requestsTabEmpty: branding.communicationArea.requestsTabEmpty.de,
        contactsTabEmpty: branding.communicationArea.contactsTabEmpty.de,
        allChatsTabEmpty: branding.communicationArea.allChatsTabEmpty.en,
        contactChatsTabEmpty: branding.communicationArea.contactChatsTabEmpty.en,
        relevantTabTitle: branding.communicationArea.relevantTabTitle.de,
        requestsTabTitle: branding.communicationArea.requestsTabTitle.de,
        contactsTabTitle: branding.communicationArea.contactsTabTitle.de,
        chatTabTitle: branding.communicationArea.chatTabTitle.de,
        peopleTabTitle: branding.communicationArea.peopleTabTitle.de,
        callsTabTitle: branding.communicationArea.callsTabTitle.de,
        allCallsTabTitle: branding.communicationArea.allCallsTabTitle.de,
        missedCallsTabTitle: branding.communicationArea.missedCallsTabTitle.de,
        scheduleTabTitle: branding.communicationArea.scheduleTabTitle.de,
        allPeopleTabTitle: branding.communicationArea.allPeopleTabTitle.de,
        allChatsTabTitle: branding.communicationArea.allChatsTabTitle.de,
        contactChatsTabTitle: branding.communicationArea.contactChatsTabTitle.de,
        blockedChatsTabTitle: branding.communicationArea.blockedChatsTabTitle.de,
        allScheduleTabTitle: branding.communicationArea.allScheduleTabTitle.de,
        pendingScheduleTabTitle: branding.communicationArea.pendingScheduleTabTitle.de,
        declinedScheduleTabTitle: branding.communicationArea.declinedScheduleTabTitle.de,
        silentChat: branding.communicationArea.silentChat.de,
        settingsTitle: branding.communicationArea.settingsTitle.de,
        closeSettingsTitle: branding.communicationArea.closeSettingsTitle.de,
        onSign: branding.communicationArea.onSign.de,
        offSign: branding.communicationArea.offSign.de,
        chatOnOffTitle: branding.communicationArea.chatOnOffTitle.de,
        audioVideoActivateTitle: branding.communicationArea.audioVideoActivateTitle.de,
        noSearchResultsTitle: branding.communicationArea.noSearchResultsTitle.de,
        scheduleTabEmpty: branding.communicationArea.scheduleTabEmpty.de,
        scheduleTabNoResult: branding.communicationArea.scheduleTabNoResult.de,
        scheduleListSectionHeaderAccepted: branding.communicationArea.scheduleListSectionHeaderAccepted.de,
        scheduleListSectionHeaderPast: branding.communicationArea.scheduleListSectionHeaderPast.de,
        scheduleListSectionHeaderRequests: branding.communicationArea.scheduleListSectionHeaderRequests.de,
        scheduleListSectionHeaderDeclined: branding.communicationArea.scheduleListSectionHeaderDeclined.de,
        personCompanyLink: branding.communicationArea.personCompanyLink.de,
        blockedContactsTitle: branding.communicationArea.blockedContactsTitle.de,
        logOutTitle: branding.communicationArea.logOutTitle.de,
        livechat: branding.communicationArea.livechat.de,
        poll: branding.communicationArea.poll.de,
        qanda: branding.communicationArea.qanda.de,
        relevantTabRelevantSectionTitle: branding.communicationArea.relevantTabRelevantSectionTitle.de,
        relevantTabAllSectionTitle: branding.communicationArea.relevantTabAllSectionTitle.de,
        requestsTabAwaitingReplySectionTitle: branding.communicationArea.requestsTabAwaitingReplySectionTitle.de,
        requestsTabMyPendingRequestsSectionTitle: branding.communicationArea.requestsTabMyPendingRequestsSectionTitle.de,
        requestsTabMyOrganizationRequestsSectionTitle: branding.communicationArea.requestsTabMyOrganizationRequestsSectionTitle.de,
        requestsTabMyExhibitorRequestsSectionTitle: branding.communicationArea.requestsTabMyExhibitorRequestsSectionTitle.de,
        contactsTabFavoritesSectionTitle: branding.communicationArea.contactsTabFavoritesSectionTitle.de,
        contactsTabMyPersonalContactsSectionTitle: branding.communicationArea.contactsTabMyPersonalContactsSectionTitle.de,
        contactsTabMyOrganizationsContactsSectionTitle: branding.communicationArea.contactsTabMyOrganizationsContactsSectionTitle.de,
        requestAcceptTitle: branding.communicationArea.requestAcceptTitle.de,
        requestIgnoreTitle: branding.communicationArea.requestIgnoreTitle.de,
        requestDeclineTitle: branding.communicationArea.requestDeclineTitle.de,
        exportVCards: branding.communicationArea.exportVCards.de,
        exportICals: branding.communicationArea.exportICals.de,
      },
      contactEntry: {
        sendMessageTitle: branding.contactEntry.sendMessageTitle.de,
        startCallTitle: branding.contactEntry.startCallTitle.de,
        inviteToCallTitle: branding.contactEntry.inviteToCallTitle.de,
        requestMeetingTitle: branding.contactEntry.requestMeetingTitle.de,
        blockPersonTitle: branding.contactEntry.blockPersonTitle.de,
        bookmarkTitle: branding.contactEntry.bookmarkTitle.de,
        unbookmarkTitle: branding.contactEntry.unbookmarkTitle.de,
        sayHelloTitle: branding.contactEntry.sayHelloTitle.de,
        downloadVCardTitle: branding.contactEntry.downloadVCardTitle.de,
        cancelRequestMessage: branding.contactEntry.cancelRequestMessage.de,
        cancelConnectionMessage: branding.contactEntry.cancelConnectionMessage.de,
        ignoredMessage: branding.contactEntry.ignoredMessage.de,
        openRequestMessage: branding.contactEntry.openRequestMessage.de,
        sendRequestMessage: branding.contactEntry.sendRequestMessage.de,
        menuOptionsTitle: branding.contactEntry.menuOptionsTitle.de,
        expoUserBadgeTitle: branding.contactEntry.expoUserBadgeTitle.de,
        speakerUserBadgeTitle: branding.contactEntry.speakerUserBadgeTitle.de,
        visitorUserBadgeTitle: branding.contactEntry.visitorUserBadgeTitle.de
      },
      sideIconBar: {
        searchIconText: branding.sideIconBar.searchIconText.de,
        homeIconText: branding.sideIconBar.homeIconText.de,
        conferenceIconText: branding.sideIconBar.conferenceIconText.de,
        seminarsIconText: branding.sideIconBar.seminarsIconText.de,
        chatroomsIconText: branding.sideIconBar.chatroomsIconText.de,
        networkIconText: branding.sideIconBar.networkIconText.de,
        expoIconText: branding.sideIconBar.expoIconText.de,
        bookmarksIconText: branding.sideIconBar.bookmarksIconText.de,
        bookmarksTooltip: branding.sideIconBar.bookmarksTooltip.de,
        historyIconText: branding.sideIconBar.historyIconText.de,
        imprintIconText: branding.sideIconBar.imprintIconText.de,
        helpIconText: branding.sideIconBar.helpIconText.de,
        profileIconText: branding.sideIconBar.profileIconText.de,
        searchBarPlaceholder: branding.sideIconBar.searchBarPlaceholder.de,
        lobbyMenuText: branding.sideIconBar.lobbyMenuText.de,
        liveMenuText: branding.sideIconBar.liveMenuText.de,
        masterclassesMenuText: branding.sideIconBar.masterclassesMenuText.de,
        programMenuText: branding.sideIconBar.programMenuText.de,
        virtualcafeMenuText: branding.sideIconBar.virtualcafeMenuText.de,
        networkingMenuText: branding.sideIconBar.networkingMenuText.de,
        mediapartnersMenuText: branding.sideIconBar.mediapartnersMenuText.de,
        showfloorMenuText: branding.sideIconBar.showfloorMenuText.de,
        experiencesMenuText: branding.sideIconBar.experiencesMenuText.de,
        goodiebagMenuText: branding.sideIconBar.goodiebagMenuText.de,
        companiesSearchDrawerText: branding.sideIconBar.companiesSearchDrawerText.de,
        peopleSearchDrawerText: branding.sideIconBar.peopleSearchDrawerText.de,
        programSearchDrawerText: branding.sideIconBar.programSearchDrawerText.de,
        productsSearchDrawerText: branding.sideIconBar.productsSearchDrawerText.de,
        trademarksSearchDrawerText: branding.sideIconBar.trademarksSearchDrawerText.de,
        newsSearchDrawerText: branding.sideIconBar.newsSearchDrawerText.de,
        viewAllCompaniesSearchDrawerText: branding.sideIconBar.viewAllCompaniesSearchDrawerText.de,
        viewAllPeopleSearchDrawerText: branding.sideIconBar.viewAllPeopleSearchDrawerText.de,
        viewAllProgramSearchDrawerText: branding.sideIconBar.viewAllProgramSearchDrawerText.de,
        viewAllProductsSearchDrawerText: branding.sideIconBar.viewAllProductsSearchDrawerText.de,
        viewAllTrademarksSearchDrawerText: branding.sideIconBar.viewAllTrademarksSearchDrawerText.de,
        viewAllNewsSearchDrawerText: branding.sideIconBar.viewAllNewsSearchDrawerText.de,
      },
      navigationArea: {
        speakersItemTitle: branding.navigationArea.speakersItemTitle.de,
        productsItemTitle: branding.navigationArea.productsItemTitle.de,
        trademarksItemTitle: branding.navigationArea.trademarksItemTitle.de,
        newsItemTitle: branding.navigationArea.newsItemTitle.de,
        sponsorsItemTitle: branding.navigationArea.sponsorsItemTitle.de,
        sessionsItemTitle: branding.navigationArea.sessionsItemTitle.de,
        liveSessionsItemTitle: branding.navigationArea.liveSessionsItemTitle.de,
        videoOnDemandItemTitle: branding.navigationArea.videoOnDemandItemTitle.de,
        futureCafeItemTitle: branding.navigationArea.futureCafeItemTitle.de,
        businessCafeItemTitle: branding.navigationArea.businessCafeItemTitle.de,
        marketingCafeItemTitle: branding.navigationArea.marketingCafeItemTitle.de,
        technologyCafeItemTitle: branding.navigationArea.technologyCafeItemTitle.de,
        networkingItemTitle: branding.navigationArea.networkingItemTitle.de,
        companiesItemTitle: branding.navigationArea.companiesItemTitle.de,
        peopleItemTitle: branding.navigationArea.peopleItemTitle.de,
        eventdateItemTitle: branding.navigationArea.eventdateItemTitle.de,
        scheduleItemTitle: branding.navigationArea.scheduleItemTitle.de,
        presenceItemTitle: branding.navigationArea.presenceItemTitle.de,
        themesItemTitle: branding.navigationArea.themesItemTitle.de,
        themeItemTitle: branding.navigationArea.themeItemTitle.de,
        presenceTypes: {
          availableState: branding.navigationArea.presenceTypes.availableState.de,
          busyState: branding.navigationArea.presenceTypes.busyState.de,
          doNotDisturbState: branding.navigationArea.presenceTypes.doNotDisturbState.de,
          offWork: branding.navigationArea.presenceTypes.offWork.de
        },
        signOutTitle: branding.navigationArea.signOutTitle.de
      },
      sponsorsPageContent: {
        sponsorsHeader: branding.sponsorsPageContent.sponsorsHeader.de,
        moreSponsorsInfo: branding.sponsorsPageContent.moreSponsorsInfo.de
      },
      sessionsSchedule: {

      },
      sessionsLive: {

      },
      eventDateTimeTable: {
        searchBarPlaceholder: branding.eventDateTimeTable.searchBarPlaceholder.de,
        sunday: branding.eventDateTimeTable.sunday.de,
        monday: branding.eventDateTimeTable.monday.de,
        tuesday: branding.eventDateTimeTable.tuesday.de,
        wednesday: branding.eventDateTimeTable.wednesday.de,
        thursday: branding.eventDateTimeTable.thursday.de,
        friday: branding.eventDateTimeTable.friday.de,
        saturday: branding.eventDateTimeTable.saturday.de,
        noBookmarksMessage: branding.eventDateTimeTable.noBookmarksMessage.de,
        noDataMessage: branding.eventDateTimeTable.noDataMessage.de
      },
      calendarEntryModalPageContent: {
        createTitle: branding.calendarEntryModalPageContent.createTitle.de,
        editTitle: branding.calendarEntryModalPageContent.editTitle.de,
        name: branding.calendarEntryModalPageContent.name.de,
        namePlaceholder: branding.calendarEntryModalPageContent.namePlaceholder.de,
        searchPerson: branding.calendarEntryModalPageContent.searchPerson.de,
        searchPersonPlaceholder: branding.calendarEntryModalPageContent.searchPersonPlaceholder.de,
        notFound: branding.calendarEntryModalPageContent.notFound.de,
        startDatePlaceholder: branding.calendarEntryModalPageContent.startDatePlaceholder.de,
        endDatePlaceholder: branding.calendarEntryModalPageContent.endDatePlaceholder.de,
        day: branding.calendarEntryModalPageContent.day.de,
        dayPlaceholder: branding.calendarEntryModalPageContent.dayPlaceholder.de,
        notes: branding.calendarEntryModalPageContent.notes.de,
        notesPlaceholder: branding.calendarEntryModalPageContent.notesPlaceholder.de,
        sendInvite: branding.calendarEntryModalPageContent.sendInvite.de,
        saveChanges: branding.calendarEntryModalPageContent.saveChanges.de,
        deleteMeeting: branding.calendarEntryModalPageContent.deleteMeeting.de,
        dayFormat: branding.calendarEntryModalPageContent.dayFormat.de
      },
      calendarEntryListView: {
        editButtonTitle: branding.calendarEntryListView.editButtonTitle.de,
        viewButtonTitle: branding.calendarEntryListView.viewButtonTitle.de,
        deleteButtonTitle: branding.calendarEntryListView.deleteButtonTitle.de,
        callButtonTitle: branding.calendarEntryListView.callButtonTitle.de,
        chatButtonTitle: branding.calendarEntryListView.chatButtonTitle.de,
        exportButtonTitle: branding.calendarEntryListView.exportButtonTitle.de,
        declineButtonTitle: branding.calendarEntryListView.declineButtonTitle.de,
        acceptButtonTitle: branding.calendarEntryListView.acceptButtonTitle.de,
        declineTitle: branding.calendarEntryListView.declineTitle.de,
        acceptTitle: branding.calendarEntryListView.acceptTitle.de,
        dateTo: branding.calendarEntryListView.dateTo.de,
        dateHourFormat: branding.calendarEntryListView.dateHourFormat.de,
        dateDayFormat: branding.calendarEntryListView.dateDayFormat.de
      },
      calendarEntryExport: {
        organizer: branding.calendarEntryExport.organizer.de,
        attendees: branding.calendarEntryExport.attendees.de
      }, productsPageContent: {
        navigationSearchPlaceholderProducts: branding.productsPageContent.navigationSearchPlaceholderProducts.de,
        loadingMessage: branding.productsPageContent.loadingMessage.de,
        noBookmarksMessage: branding.productsPageContent.noBookmarksMessage.de
      },
      trademarksPageContent: {
        navigationSearchPlaceholderTrademarks: branding.trademarksPageContent.navigationSearchPlaceholderTrademarks.de,
        loadingMessage: branding.trademarksPageContent.loadingMessage.de,
        noBookmarksMessage: branding.trademarksPageContent.noBookmarksMessage.de
      },
      exhibitorsPageContent: {
        navigationSearchPlaceholderCompanies: branding.exhibitorsPageContent.navigationSearchPlaceholderCompanies.de,
        loadingMessage: branding.exhibitorsPageContent.loadingMessage.de,
        noBookmarksMessage: branding.exhibitorsPageContent.noBookmarksMessage.de,
        onlyShowBookmarksTitle: branding.programSchedule.onlyShowBookmarksTitle.de,
      },
      myProfilePageContent: {
        networkingTitle: branding.myProfilePageContent.networkingTitle.de,
        firstnamePlaceholder: branding.myProfilePageContent.firstnamePlaceholder.de,
        lastnamePlaceholder: branding.myProfilePageContent.lastnamePlaceholder.de,
        companyPlaceholder: branding.myProfilePageContent.companyPlaceholder.de,
        jobtitlePlaceholder: branding.myProfilePageContent.jobtitlePlaceholder.de,
        responsibilityPlaceholder: branding.myProfilePageContent.responsibilityPlaceholder.de,
        interestsPlaceholder: branding.myProfilePageContent.interestsPlaceholder.de,
        mobilePlaceholder: branding.myProfilePageContent.mobilePlaceholder.de,
        numberPlaceholder: branding.myProfilePageContent.numberPlaceholder.de,
        profileinfoPlaceholder: branding.myProfilePageContent.profileinfoPlaceholder.de,
        updateDataButtonText: branding.myProfilePageContent.updateDataButtonText.de
      },
      personDetailPageContent: {
        navbarText: branding.personDetailPageContent.navbarText.de,
        cancelRequestMessage: branding.personDetailPageContent.cancelRequestMessage.de,
        cancelConnectionMessage: branding.personDetailPageContent.cancelConnectionMessage.de,
        ignoredMessage: branding.personDetailPageContent.ignoredMessage.de,
        openRequestMessage: branding.personDetailPageContent.openRequestMessage.de,
        sendRequestMessage: branding.personDetailPageContent.sendRequestMessage.de,
        chatText: branding.personDetailPageContent.chatText.de,
        videoCallText: branding.personDetailPageContent.videoCallText.de,
        voiceCallText: branding.personDetailPageContent.voiceCallText.de,
        voiceCallingText: branding.personDetailPageContent.voiceCallingText.de,
        meetingText: branding.personDetailPageContent.meetingText.de,
      },
      sayHelloModal: {
        cancelRequestMessage: branding.sayHelloModal.cancelRequestMessage.de,
        cancelConnectionMessage: branding.sayHelloModal.cancelConnectionMessage.de,
        ignoredMessage: branding.sayHelloModal.ignoredMessage.de,
        openRequestMessage: branding.sayHelloModal.openRequestMessage.de,
        sendMessagePlaceholder: branding.sayHelloModal.sendMessagePlaceholder.de,
        headerTextSayHello: branding.sayHelloModal.headerTextSayHello.de,
        headerTextCancelRequest: branding.sayHelloModal.headerTextCancelRequest.de,
        submitTextAccept: branding.sayHelloModal.submitTextAccept.de,
        submitTextIgnore: branding.sayHelloModal.submitTextIgnore.de,
        submitTextYes: branding.sayHelloModal.submitTextYes.de,
        submitTextSend: branding.sayHelloModal.submitTextSend.de,
        submitTextClose: branding.sayHelloModal.submitTextClose.de,
      },
      filterBar: {
        allFilterTitle: branding.filterBar.allFilterTitle.de,
      },
      organizationDetailPageContent: {
        navbarTitle: branding.organizationDetailPageContent.navbarTitle.de,
        sectionLineContactPerson: branding.organizationDetailPageContent.sectionLineContactPerson.de,
        sectionLineCategories: branding.organizationDetailPageContent.sectionLineCategories.de,
        sectionLineProductDescription: branding.organizationDetailPageContent.sectionLineProductDescription.de,
        buttonShowMore: branding.organizationDetailPageContent.buttonShowMore.de,
        buttonCollapse: branding.organizationDetailPageContent.buttonCollapse.de
      },
      productDetailPageContent: {
        navbarText: branding.productDetailPageContent.navbarText.de
      },
      trademarkDetailPageContent: {
        navbarText: branding.trademarkDetailPageContent.navbarText.de
      },
      eventDateDetailPageContent: {
        personsTitle: branding.eventDateDetailPageContent.personsTitle.de,
        galleryTitle: branding.eventDateDetailPageContent.galleryTitle.de,
        noPersonsMessage: branding.eventDateDetailPageContent.noPersonsMessage.de
      },
      infoPage: {
        management: {
          title: branding.infoPage.management.title.de
        },
        persons: {
          title: branding.infoPage.persons.title.de,
          item1: branding.infoPage.persons.item1.de,
          item2: branding.infoPage.persons.item2.de
        },
        headquarters: {
          title: branding.infoPage.headquarters.title.de
        },
        vatNumber: {
          title: branding.infoPage.vatNumber.title.de
        }
      },
      networkingArea: {
        filterByTypeText: branding.networkingArea.filterByTypeText.de,
        filterByPositionText: branding.networkingArea.filterByPositionText.de,
        showMyContactsText: branding.networkingArea.showMyContactsText.de,
        sayHelloText: branding.networkingArea.sayHelloText.de,
        showMoreText: branding.networkingArea.showMoreText.de,
        startVideoAudioChatText: branding.networkingArea.startVideoAudioChatText.de,
        startTextChat: branding.networkingArea.startTextChat.de,
        connectedText: branding.networkingArea.connectedText.de,
        loadMoreConnectionsText: branding.networkingArea.loadMoreConnectionsText.de,
        showTacticalViewText: branding.networkingArea.showTacticalViewText.de,
        showSecondGenText: branding.networkingArea.showSecondGenText.de,
        showRelevantConnectionsText: branding.networkingArea.showRelevantConnectionsText.de,
      },
      programPageContent: {
        programPageTitle: branding.programPageContent.programPageTitle.de,
        scheduleTabTitle: branding.programPageContent.scheduleTabTitle.de,
        speakersTabTitle: branding.programPageContent.speakersTabTitle.de,
      },
      programSchedule: {
        searchBarPlaceholder: branding.programSchedule.searchBarPlaceholder.de,
        sunday: branding.programSchedule.sunday.de,
        monday: branding.programSchedule.monday.de,
        tuesday: branding.programSchedule.tuesday.de,
        wednesday: branding.programSchedule.wednesday.de,
        thursday: branding.programSchedule.thursday.de,
        friday: branding.programSchedule.friday.de,
        saturday: branding.programSchedule.saturday.de,
        noBookmarksMessage: branding.programSchedule.noBookmarksMessage.de,
        noDataMessage: branding.programSchedule.noDataMessage.de,
        onlyShowBookmarksTitle: branding.programSchedule.onlyShowBookmarksTitle.de,
      },
      programSpeakers: {
        navigationSearchPlaceholderSpeakers: branding.programSpeakers.navigationSearchPlaceholderSpeakers.de,
        loadingMessage: branding.programSpeakers.loadingMessage.de,
        noBookmarksMessage: branding.programSpeakers.noBookmarksMessage.de,
        onlyShowBookmarksTitle: branding.programSpeakers.onlyShowBookmarksTitle.de,
      },
    }
  }
};

export const useLanguageState = createState(strings)[self].map(s => () => {
  const state = useState(s)
  return ({
    setLanguage: (language: string) => {
      state[self].set(prevState => {
        if (prevState.availableLanguages.indexOf(language) > -1) {
          prevState.language = language
        }
        localStorage.setItem(localStorageLanguageKey, prevState.language)
        return prevState
      })
    },
    getLanguage: () => {
      return state[self].value.language
    },
    getStrings: () => {
      const language = state[self].value.language
      const stringKeys = Object.keys(state[self].value.strings)
      for (let index = 0; index < stringKeys.length; index++) {
        const languageKey = stringKeys[index];
        if (languageKey === language) {
          return Object.values(state[self].value.strings)[index]
        }
      }

      return Object.values(state[self].value.strings)[0]
    },
    getAvailableLanguages: () => {
      return state[self].value.availableLanguages
    }
  })
})

