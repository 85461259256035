import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'rsuite/dist/styles/rsuite-default.css';
import 'react-bootstrap/dist/react-bootstrap.min.js';

import GlobalErrorBoundary from './contentArea/errorPages/GlobalErrorBoundary';
import eventState, { EventPhase } from './globalStates/EventState';
import CenteredLoader from './ui/CenteredLoader';
import { BrowserRouter as Router, useRouteMatch } from "react-router-dom";

const SystemCheck = React.lazy(() => import('./systemCheck/SystemCheck'))
const PreLoginApp = React.lazy(() => import('./PreLoginApp'))
const App = React.lazy(() => import('./App'))



function Application() {
  const checkMatch = useRouteMatch("/check*")
  if (checkMatch) {
    return <SystemCheck />
  } else if (eventState.phase === EventPhase.EVENT_ANNOUNCEMENT || eventState.phase === EventPhase.TICKET_SALE) {
    return <PreLoginApp />
  } else {
    return <App />
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<CenteredLoader>Loading...</CenteredLoader>}>
      <GlobalErrorBoundary>
        <Router>
          <Application />
        </Router>
      </GlobalErrorBoundary>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('virtualGuide')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
