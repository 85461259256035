import branding, { EventTiming } from '../branding/branding'
import moment from "moment"
import queryString from 'query-string'

/**
 * @see https://confluence.corussoft.de/display/DEP/Event+Phases
 */
export enum EventPhase {
    EVENT_ANNOUNCEMENT = "EVENT_ANNOUNCEMENT", // the page / domain is ready and shows that there is a fair in the near future 
    TICKET_SALE = "TICKET_SALE", // the ticket shop is open and you can navigate to the store to accquire a ticket but you are not able to log in at the moment
    EXPLORATION = "EXPLORATION", // the login is open so you are able to log in. Inside of the application you have limited functionality (live feature are deactivated but you see placesholders / coming soon phrases)
    LIVE = "LIVE", // the real shit happens
    POST_EVENT = "POST_EVENT" // the app provides video on demand content and community features
}


export interface EventState {
    phase: EventPhase,
}

const timeTravelLocalStorageKey = "virtualGuide-timetravel"
const forcePhaseLocalStorageKey = "virtualGuide-forcephase"

function calcCurrentEventPhase(eventTiming: EventTiming): EventPhase {
    const queryParams: any = queryString.parse(window.location.search)
    const forcePhase = getForcePhase(queryParams)
    if(forcePhase) {
        return forcePhase
    }

    const ticketShopOpenMoment = moment(eventTiming.ticketShopOpenDateTime)
    const loginOpenMoment = moment(eventTiming.loginOpenDateTime)
    const eventStartMoment = moment(eventTiming.eventStartDateTime)
    const eventEndMoment = moment(eventTiming.eventEndDateTime)

    let now = getNow(queryParams)

    if (now.isBefore(ticketShopOpenMoment)) {
        return EventPhase.EVENT_ANNOUNCEMENT
    } else if (now.isBefore(loginOpenMoment)) {
        return EventPhase.TICKET_SALE
    } else if (now.isBefore(eventStartMoment)) {
        return EventPhase.EXPLORATION
    } else if (now.isBefore(eventEndMoment)) {
        return EventPhase.LIVE
    } else {
        return EventPhase.POST_EVENT
    }
}


function getForcePhase(queryParams: any) : EventPhase | undefined {
    if (queryParams.forcephase) {
        const forcePhase = queryParams.forcephase as EventPhase
        if(Object.values(EventPhase).includes(forcePhase)) {
            localStorage.setItem(forcePhaseLocalStorageKey, forcePhase)
            return forcePhase
        } else {
            localStorage.removeItem(forcePhaseLocalStorageKey)
        }
    }

    const forcePhaseString = localStorage.getItem(forcePhaseLocalStorageKey)
    if(!forcePhaseString)
        return undefined
    const forcePhase = forcePhaseString as EventPhase
    return Object.values(EventPhase).includes(forcePhase) ? forcePhase : undefined
}


function getNow(queryParams: any) {
    if (queryParams.timetravel) {
        const targetTime = moment(queryParams.timetravel)
        if (targetTime.isValid()) {
            localStorage.setItem(timeTravelLocalStorageKey, targetTime.format())
            return targetTime
        } else {
            localStorage.removeItem(timeTravelLocalStorageKey)
        }
    }

    const storedTargetTimeString = localStorage.getItem(timeTravelLocalStorageKey)
    if (storedTargetTimeString) {
        const storedTargetTime = moment(storedTargetTimeString)
        if (storedTargetTime.isValid()) {
            return storedTargetTime
        } else {
            localStorage.removeItem(timeTravelLocalStorageKey)
        }
    }

    return moment()
}

const eventState: EventState = { phase: calcCurrentEventPhase(branding.eventTiming) }

export default eventState
