import React from "react"
import ErrorSite from "./ErrorSite";
import styled from "styled-components";



const GlobalErrorBoundaryPanel = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
`


class GlobalErrorBoundary extends React.Component {
    state = {
        error: null
    }

    static getDerivedStateFromError(error: any) {  
        return { error: error };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // TODO possible log error somewhere
    }

    render() {
        if (this.state.error) {
            return (
                <GlobalErrorBoundaryPanel>
                    <ErrorSite/>
                </GlobalErrorBoundaryPanel>
            )
        }
        return this.props.children;
    }
}

export default GlobalErrorBoundary