import React from "react"
import styled from "styled-components"
import { Loader } from "rsuite"

const LoaderWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    & .rs-loader-lg .rs-loader {
        height: 100%;
    }
`
type CenteredLoaderProps = {
    size?: string,
    className?: string, 
    loaderWrapperStyle?: React.CSSProperties | undefined
}
const CenteredLoader: React.FunctionComponent<CenteredLoaderProps> = (props) => {
    return <LoaderWrapper style={props.loaderWrapperStyle} className={props.className}><Loader size={props.size ? props.size : "lg"} center /></LoaderWrapper>
}
export default CenteredLoader