import { OrganizationDetailPageContentBranding } from './../contentArea/detailPages/OrganizationDetailPageContent';
import { InfosPageContentBranding } from './../contentArea/InfosPageContent';
import { ExhibitorsPageContentBranding } from './../contentArea/ExhibitorsPageContent';
import { TrademarksPageContentBranding } from './../contentArea/TrademarksPageContent';
import { ProductsPageContentBranding } from './../contentArea/ProductsPageContent';
import { EventDateDetailPageContentBranding } from './../contentArea/detailPages/EventDateDetailPageContent';
import { TrademarkDetailPageContentBranding } from './../contentArea/detailPages/TrademarkDetailPageContent';
import { ProductDetailPageContentBranding } from './../contentArea/detailPages/ProductDetailPageContent';
import { SayHelloModalBranding } from './../ui/SayHelloModal';
import { FilterBarBranding } from '../ui/FilterBar';
import { PersonDetailPageContentBranding } from './../contentArea/detailPages/PersonDetailPageContent';
import { MyProfilePageContentBranding } from './../contentArea/MyProfilePageContent';
import { EventDateTimeTableBranding } from './../contentArea/sessions/EventDateTimeTable';
import { SessionsScheduleBranding } from './../contentArea/sessions/Schedule';
import { SessionsLiveBranding } from './../contentArea/sessions/Live';
import { NavigationAreaBranding } from './../navigationArea/NavigationArea';
import { ReceptionPageSiteBranding } from '../contentArea/reception/ReceptionPageContent';
import { BookmarkedSpeakersBranding } from '../contentArea/reception/BookmarkedSpeakers';
import { BookmarkedExhibitorsBranding } from '../contentArea/reception/BookmarkedExhibitors';
import { GlobalStatePopupTextsBranding } from './../globalStates/GlobalStatePopupTextsBranding'
import { LoginRegistrationSiteBranding } from '../contentArea/loginregistration/LoginRegistrationSite'
import { ErrorSiteBranding } from '../contentArea/errorPages/ErrorSite'
import { CommunicationAreaBranding } from '../communicationArea/CommunicationArea'
import { ContactEntryBranding } from '../communicationArea/ContactEntry'
import { SideIconBarBranding } from '../navigationArea/SideIconBar';
import { SponsorsPageContentBranding } from '../contentArea/SponsorsPageContent';
import { NetworkingAreaBranding } from '../contentArea/NetworkingPageContent'
import { CalendarEntryModalBranding } from '../ui/CalendarEntryModal';
import { CalendarEntryListViewBranding } from '../communicationArea/CalendarEntryListView';
import { MeetingRoomGroup } from '../conference/ConferencePageContent';
import { ProgramPageContentBranding } from '../contentArea/program/ProgramPageContent';
import { ProgramScheduleBranding } from '../contentArea/program/Schedule';
import { ProgramSpeakersBranding } from '../contentArea/program/Speakers';
import { ConferenceTexts } from '../conference/types/ConferenceTexts';
import { GlobalTextsBranding } from '../globalStates/GlobalTextsBranding'
import { ThirdPartyResources } from './ThirdPartyResources';
import { TicketSaleBranding } from '../PreLoginApp';
import { CalendarEntryExprtBranding } from '../communicationArea/ICal';



export interface Branding {
    configuration: Configuration
    eventTiming: EventTiming
    pageTitle: string
    pageDescription: string
    availableLanguages: string[]
    globalStatePopupTexts: GlobalStatePopupTextsBranding
    loginRegistrationSite: LoginRegistrationSiteBranding
    errorSite: ErrorSiteBranding
    communicationArea: CommunicationAreaBranding
    contactEntry: ContactEntryBranding
    sideIconBar: SideIconBarBranding
    navigationArea: NavigationAreaBranding
    sponsorsPageContent: SponsorsPageContentBranding
    eventDateTimeTable: EventDateTimeTableBranding
    sessionsSchedule: SessionsScheduleBranding
    sessionsLive: SessionsLiveBranding
    calendarEntryModalPageContent: CalendarEntryModalBranding
    calendarEntryListView: CalendarEntryListViewBranding
    calendarEntryExport: CalendarEntryExprtBranding
    myProfilePageContent: MyProfilePageContentBranding
    personDetailPageContent: PersonDetailPageContentBranding
    sayHelloModal: SayHelloModalBranding
    filterBar: FilterBarBranding
    organizationDetailPageContent: OrganizationDetailPageContentBranding
    productDetailPageContent: ProductDetailPageContentBranding
    trademarkDetailPageContent: TrademarkDetailPageContentBranding
    eventDateDetailPageContent: EventDateDetailPageContentBranding
    productsPageContent: ProductsPageContentBranding
    trademarksPageContent: TrademarksPageContentBranding
    exhibitorsPageContent: ExhibitorsPageContentBranding
    programPageContent: ProgramPageContentBranding
    programSchedule: ProgramScheduleBranding
    programSpeakers: ProgramSpeakersBranding
    receptionPage: ReceptionPageSiteBranding
    bookmarkedSpeakers: BookmarkedSpeakersBranding
    bookmarkedExhibitors: BookmarkedExhibitorsBranding
    meetingRoomGroups: MeetingRoomGroup[]
    conferenceTexts: ConferenceTexts
    conferenceListTiles: ConferenceListTiles
    thirdPartyResources: ThirdPartyResources[]
    helpPage: string
    theme1Color: string
    theme2Color: string
    theme3ColorPrimary: string
    theme3ColorSecondary: string
    bannerURL: string
    receptionFooterURL: string
    infoPage: InfosPageContentBranding
    moreButtonColor1: string
    moreButtonColor2: string
    video1: string
    video2: string
    video3: string
    font1: string
    font2: string
    networkingArea: NetworkingAreaBranding
    filterButtonColor: string
    globalTexts: GlobalTextsBranding
    ticketSale: TicketSaleBranding
}

export interface EventTiming {
    ticketShopOpenDateTime: string // ISO_8601 "2000-01-01T00:00:00+02:00"
    loginOpenDateTime: string // ISO_8601 "2000-01-01T00:00:00+02:00"
    eventStartDateTime: string // ISO_8601 "2000-01-01T00:00:00+02:00"
    eventEndDateTime: string // ISO_8601 "2020-09-25T00:00:00+02:00"
}

export interface LocalizedString {
    de: string
    en: string
}

export interface Configuration {
    topicName: string
    sotName: string
    sotAccessToken: string
}

export interface ConferenceListTiles {
    gradientDivBackgroundFirst: string
    gradientDivBackgroundSecond: string
    headerStickyBackground: string
    cardBackground: string
    corouselBackground: string
    sponsorsBackground1: string
    sponsorsBackground2: string
    sponsorsBackground3: string
    sponsorsBackground4: string
    sponsorsBackground5: string
}

declare const branding: Branding
function getBranding(): Branding {
    return branding
}
export default getBranding()